import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/ag-grid-community/styles/ag-grid.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/ag-grid-community/styles/ag-theme-balham.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/core/styles.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/dates/styles.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/notifications/styles.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/spotlight/styles.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/dropzone/styles.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@mantine/tiptap/styles.css");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\"}],\"variableName\":\"inter\"}");
import(/* webpackMode: "eager" */ "/vercel/path0/src/app/ShepherdProvider.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/global.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/styles/fonts.css")