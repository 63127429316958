import { snakeCase } from 'lodash';

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const capitalizeEveryFirstLetter = (str: string) => {
  const words = str.split(' ');
  const capitalizedWords = words.map((word) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  });

  return capitalizedWords.join(' ');
};

const humanizeString = (string: string) => {
  const newString = snakeCase(string)
    .toLowerCase()
    .replace(/[_-]+/g, ' ')
    .replace(/\s{2,}/g, ' ')
    .trim();

  return newString.charAt(0).toUpperCase() + newString.slice(1);
};

export const humanizeEnum = (string: string) => {
  const lowered = string.toLowerCase();
  const split = lowered.split('_');
  const capitalized = split.map((item) => capitalizeFirstLetter(item));
  return capitalized.join(' ');
};

export const humanizeStringEveryUpper = (string: string) => {
  return humanizeString(string)
    .split(' ')
    .map((item) => capitalizeEveryFirstLetter(item))
    .join(' ');
};

export const humanizeStringFirstUpper = (string: string) => {
  return humanizeString(string)
    .split(' ')
    .map((item) => capitalizeFirstLetter(item))
    .join(' ');
};
export default humanizeString;
