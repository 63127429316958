import { getCookie } from 'cookies-next';

import {
  ApolloClient,
  ApolloLink,
  from,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';

import { createUploadLink } from 'apollo-upload-client';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/nextjs';
import { SentryLink } from 'apollo-link-sentry';
import defaults from 'lodash/defaults';
import { useMemo } from 'react';

import { $TsFixMe } from '../module';
import { API_URL, COOKIE_NAME, IS_DEV } from './constants';
import { TypedTypePolicies } from './graphql/apolloHelpers.generated';
import possibleTypes from './graphql/possibleTypes.generated.json';
import humanizeString from './utils/humanizeString';

let apolloClient: ApolloClient<NormalizedCacheObject>;

type ErrorCallback = (data: { title: string; message: string; level: string }) => void;

type Options = {
  errorCallback?: ErrorCallback;
};

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} as { [key: string]: $TsFixMe } }) => {
    const cookie = getCookie(COOKIE_NAME);

    if (IS_DEV) {
      // const transaction = Sentry.startTransaction({ name: 'graphql-request' });
      // Sentry.getCurrentHub().configureScope((scope) => scope.setSpan(transaction));

      return {
        headers: defaults(headers, {
          authorization: cookie ? `Bearer ${cookie}` : '',
          // 'sentry-trace': transaction?.toTraceparent(),
        }),
      };
    }

    return {
      headers: defaults(headers, {
        authorization: cookie ? `Bearer ${cookie}` : '',
      }),
    };
  });

  return forward(operation);
});

const sentryLink = new SentryLink({
  attachBreadcrumbs: {
    includeQuery: true,
    includeVariables: true,
    includeError: true,
    includeFetchResult: true,
  },
});

const createLink = () => {
  const errorLink = onError(({ operation, networkError, graphQLErrors, forward }) => {
    Sentry.withScope((scope) => {
      scope.setTransactionName(operation.operationName);
      scope.setContext('apolloGraphQLOperation', {
        operationName: operation.operationName,
        variables: operation.variables,
        extensions: operation.extensions,
      });

      graphQLErrors?.forEach((error) => {
        Sentry.captureMessage(error.message, {
          level: 'error',
          fingerprint: ['{{ default }}', '{{ transaction }}'],
          contexts: {
            apolloGraphQLError: {
              error,
              message: error.message,
              extensions: error.extensions,
            },
          },
        });
      });

      if (networkError) {
        Sentry.captureMessage(networkError.message, {
          level: 'error',
          contexts: {
            apolloNetworkError: {
              error: networkError,
              extensions: (networkError as any).extensions,
            },
          },
        });
      }
    });
  });

  return from([
    authLink,
    sentryLink,
    errorLink,
    createUploadLink({
      uri: `${API_URL}/api/graphql`,
    }),
  ]);
};

const typePolicies: TypedTypePolicies = {
  ApplicationFormFieldConfig: {
    keyFields: ['name', 'label'],
  },
  ApplicationFormConfig: {
    fields: {
      fields: {
        merge: false,
      },
    },
  },
  Query: {
    fields: {
      policyholdersV2: {
        keyArgs: false,
      },
      primaryGeneralLiabilityConstants: {
        keyArgs: false,
      },
    },
  },
};

export const createApolloClient = (options: Options) =>
  new ApolloClient({
    defaultOptions: {
      watchQuery: {
        notifyOnNetworkStatusChange: true,
      },
      query: {
        notifyOnNetworkStatusChange: true,
      },
    },
    ssrMode: typeof window === 'undefined',
    link: createLink(),
    cache: new InMemoryCache({
      possibleTypes: possibleTypes.possibleTypes,
      typePolicies,
    }),
  });

export function initializeApollo(initialState: any = null, options?: Options) {
  // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle
  const privateApolloClient =
    apolloClient ??
    createApolloClient(
      // @ts-expect-error
      options
    );

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // get hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    // const existingCache = privateApolloClient.extract();

    // // Restore the cache using the data passed from
    // // getStaticProps/getServerSideProps combined with the existing cached data
    // privateApolloClient.cache.restore({ ...existingCache, ...initialState });
    privateApolloClient.cache.restore(initialState);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return privateApolloClient;
  // Create the Apollo Client once in the client, only when session is present
  if (!apolloClient) {
    apolloClient = privateApolloClient;
  }

  return privateApolloClient;
}

// @ts-expect-error
export function useApollo(initialState) {
  const store = useMemo(
    () =>
      initializeApollo(initialState, {
        errorCallback: (error) => {
          if (typeof window !== 'undefined') {
            console.error(humanizeString(error.message || ''));
          }
        },
      }),
    [initialState]
  );
  return store;
}
